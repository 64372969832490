import { Box, chakra } from '@chakra-ui/react';

const ChakraRichTextContainer = chakra(Box, {
  label: 'bfs-chakra-rich-text-container',
  baseStyle: {
    '> p': {
      margin: '16px 0',
    },
    '> ol': {
      margin: '0 32px',
    },
  },
});

export default { ChakraRichTextContainer };
