/* eslint-disable no-underscore-dangle */
import React from 'react';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import readingTime from 'reading-time';
import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

import HERO from '@/components/hero/Hero.component';
import SEO from '@/components/Seo';
import { graphql, Link } from 'gatsby';
import ContainerStyles from '@/components/container/Container.styles';
import RichTextStyles from '@/common/styled-components/RichText.styles';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import BlogUtils from '@/common/utils/Blog.utils';
import NavStyles from '@/components/navigation/Nav.styles';
import Tags from '@/components/tags';
import { IPageProps } from '@/common/types/page.types';

function BlogPostTemplate({ data, location }): JSX.Element {
  const { contentfulBlogPost, previous, next } = data;
  const {
    contentReferences,
    body,
    description,
    heroImage,
    title,
    author,
    rawDate,
    publishDate,
    tags,
  } = contentfulBlogPost;

  const plainTextDescription = documentToPlainTextString(
    JSON.parse(description.raw),
  );
  const plainTextBody = documentToPlainTextString(JSON.parse(body?.raw));
  const { minutes: timeToRead } = readingTime(plainTextBody);

  return (
    <>
      <SEO
        title={title}
        description={plainTextDescription}
        cardImage={`http:${heroImage?.resize?.src}`}
        keywords={tags.map((tag) => tag.tagText).join(', ')}
        pathname={location.pathname}
      />
      <HERO image={heroImage.gatsbyImage} showTitle={false} title={title} />
      <ContainerStyles.PageContainer
        paddingLeft={['12px !important', '12rem !important']}
        paddingRight={['12px !important', '12rem !important']}
        paddingBottom={6}
      >
        {/* Blog Heading */}
        <Box mb={8}>
          <Heading>{title}</Heading>
          <Text maxWidth={['100%', '66%']}>{plainTextDescription}</Text>

          <Stack position="unset" mt="8px" direction={['column', 'row']}>
            <Text fontWeight="bold">By: {author.name}</Text>
            <Text display={['none', 'unset']}>&middot;</Text>
            <time dateTime={rawDate}>{publishDate}</time>
            <Text>{timeToRead} minute read</Text>
          </Stack>
        </Box>

        {/* Blog Body */}
        <RichTextStyles.ChakraRichTextContainer>
          {body.raw && renderRichText(body, BlogUtils.richTextOptions)}
        </RichTextStyles.ChakraRichTextContainer>

        {/* Content References */}
        {BlogUtils.renderContentReferences(contentReferences)}

        {/* Tags */}
        <Tags tags={tags} />

        {/* Navigation */}
        {(previous || next) && (
          <NavStyles.BlogFooterNav>
            <UnorderedList listStyleType="none" display="flex">
              {previous && (
                <ListItem key="previous-link" flex={1}>
                  <Link to={`/blog/${previous.slug}`} rel="prev">
                    ← {previous.title}
                  </Link>
                </ListItem>
              )}
              {next && (
                <ListItem key="next-link">
                  <Link to={`/blog/${next.slug}`} rel="next">
                    {next.title} →
                  </Link>
                </ListItem>
              )}
            </UnorderedList>
          </NavStyles.BlogFooterNav>
        )}
      </ContainerStyles.PageContainer>
    </>
  );
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  ) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      author {
        name
      }
      publishDate(formatString: "MMMM Do, YYYY")
      rawDate: publishDate
      heroImage {
        gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
      body {
        raw
      }
      description {
        raw
      }
      contentReferences {
        ...CodeComponentFragment
      }
      tags {
        tagText
        __typename
      }
    }
    previous: contentfulBlogPost(slug: { eq: $previousPostSlug }) {
      slug
      title
    }
    next: contentfulBlogPost(slug: { eq: $nextPostSlug }) {
      slug
      title
    }
  }
`;
