/* eslint-disable no-underscore-dangle */
import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import CODE, {
  modelName as CodeComponentModelName,
} from '@/components/code/Code.component';
import QUOTE, {
  modelName as QuoteComponentModelName,
} from '@/components/text/Quote.component';
import WALL_OF_TEXT, {
  modelName as WallOfTextComponentModelName,
} from '@/components/text/WallOfText.component';

const richTextOptions: any = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: {
      data: { target: { gatsbyImage: any; description: any } };
    }): any => {
      const { data } = node;
      const { gatsbyImage, description } = data.target;
      return (
        <GatsbyImage
          image={getImage(gatsbyImage)}
          alt={description || 'Blog Image'}
        />
      );
    },
  },
};

const renderContentReferences = (contentReferences: {
  map: (arg0: (reference: any) => JSX.Element) => JSX.Element;
}): JSX.Element => {
  if (contentReferences) {
    return contentReferences.map((reference) => {
      switch (reference.__typename) {
        case CodeComponentModelName: {
          return <CODE {...reference} />;
        }
        case QuoteComponentModelName: {
          return <QUOTE {...reference} />;
        }
        case WallOfTextComponentModelName: {
          return <WALL_OF_TEXT {...reference} />;
        }
        default:
          return null;
      }
    });
  }
  return null;
};

export default { richTextOptions, renderContentReferences };
