import React from 'react';
import { graphql } from 'gatsby';
import TSReactGist from '@befreestudios/ts-react-gist';
import CodeComponentContainer from './Code.styles';

export const modelName = 'ContentfulCodeComponent';

function CODE({ url }: Queries.ContentfulCodeComponent): JSX.Element {
  return url ? (
    <CodeComponentContainer data-testid="code-component-container">
      <TSReactGist url={url} />
    </CodeComponentContainer>
  ) : null;
}

export default CODE;

export const codeComponentQuery = graphql`
  fragment CodeComponentFragment on ContentfulCodeComponent {
    url
    __typename
  }
`;
