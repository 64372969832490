import React from 'react';
import { graphql } from 'gatsby';
import { Text } from '@chakra-ui/react';

export const modelName = 'ContentfulQuoteComponent';

function QUOTE({
  quote,
  author,
  theme,
}: Queries.ContentfulQuoteComponent): JSX.Element {
  return quote && quote.quote ? (
    <div
      style={{
        backgroundColor: theme,
        padding: '30px',
        width: '80%',
        margin: '0 0 auto',
      }}
    >
      <Text>{quote?.quote}</Text>
      {author ? (
        <h4 style={{ width: '100%', textAlign: 'right', fontStyle: 'italic' }}>
          ---- {author}
        </h4>
      ) : null}
    </div>
  ) : null;
}
export default QUOTE;

export const quoteComponentQuery = graphql`
  fragment QuoteComponentFragment on ContentfulQuoteComponent {
    author
    quote {
      quote
    }
    theme
    __typename
  }
`;
