import React from 'react';
import { graphql } from 'gatsby';
import RichText from '@madebyconnor/rich-text-to-jsx';

export const modelName = 'ContentfulWallOfTextComponent';

function WALL_OF_TEXT({
  text,
}: Queries.ContentfulWallOfTextComponent): JSX.Element {
  return text && text.raw ? <RichText richText={JSON.parse(text.raw)} /> : null;
}
export default WALL_OF_TEXT;

export const wallComponentQuery = graphql`
  fragment WallOfTextComponentFragment on ContentfulWallOfTextComponent {
    text {
      raw
    }
    __typename
  }
`;
