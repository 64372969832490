import { Box, chakra } from '@chakra-ui/react';

const CodeComponentContainer = chakra(Box, {
  label: 'bfs-code-component-container',
  baseStyle: {
    margin: {
      base: '16px 0',
    },
  },
});

export default CodeComponentContainer;
